.App {
  text-align: left;
  margin-top: 60px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.login-container{
  position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border: 1px solid #eee;
    padding: 55px;
    box-shadow: 1px 1px 8px #eee;
    width: 600px;
    text-align: center;
    height: 400px;
    display: flex;
    align-items: center;
}

.login-container .btn-secondary{
  background-color: #00e277;
    border-color: #00e277;
}
.login-container .btn-secondary:hover{
  background-color: #00e277;
    border-color: #00e277;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


