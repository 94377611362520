.step-indicator {
	margin-top: 50px;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.step {
	display: flex;
	align-items: center;
	flex-direction: row;
	position: relative;
	z-index: 1;
	width: 100%;

	.row {
		width: 100%;
	}

	.step-header {
		text-align: right;
		font-weight: bold;
	}
	.step-text {
		text-align: right;
	}

	.circle {
		background: white;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		position: absolute;
		right: -17px;
		top: 20px;
		border: 1px solid #00e277;

		&.active {
			background: #00e277;
		}
	}
}

hr {
	margin: 2rem 0 !important;
}

.border-right {
	border-right: 1px solid #dee2e6 !important;
}

.indicator-wrapper {
	width: 100%;
	height: 50px;
}

.step-indicator .step-icon {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background: transparent;
	border: 2px solid #00e277;
	color: #00e277;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	transition: 0.5s;
	margin: 0 auto;

	.icon-image {
		width: 25px;
		height: 25px;

		&.rocket {
			-webkit-mask: url(../../resources/icon-rocket.svg) no-repeat center;
			mask: url(../../resources/icon-rocket.svg) no-repeat center;
			background-color: #00e277;
		}
		&.code {
			-webkit-mask: url(../../resources/icon-code.svg) no-repeat center;
			mask: url(../../resources/icon-code.svg) no-repeat center;
			background-color: #00e277;
			-webkit-mask-size: contain;
		}
		&.info {
			-webkit-mask: url(../../resources/icon-info.svg) no-repeat center;
			mask: url(../../resources/icon-info.svg) no-repeat center;
			background-color: #00e277;
		}
		&.email {
			-webkit-mask: url(../../resources/icon-email.svg) no-repeat center;
			mask: url(../../resources/icon-email.svg) no-repeat center;
			background-color: #00e277;
		}
		&.active {
			background-color: #ffffff;
		}
	}
}

.step.active .step-icon {
	background: #00e277;
	color: #ffffff;
}

.step p {
	text-align: center;
	position: absolute;
	bottom: -40px;
	color: #c2c2c2;
	font-size: 14px;
	font-weight: bold;
}

.step.active p {
	color: #00e277;
}

.step.step2 p,
.step.step3 p {
	left: 50%;
	transform: translateX(-50%);
}

.line-wrapper {
	position: relative;

	.indicator-line {
		width: 1px;
		height: 50px;
		flex: 1;
		transition: width 0.5s;
		background: #00e277;
		margin: 0 auto;
	}

	.thick-line {
		width: 3px;
		height: 0px;
		flex: 1 1;
		transition: width 0.5s;
		background: #00e277;
		margin: 0 auto;
		position: absolute;
		top: 0;
		right: calc(50% - 2px);
		transition: height 0.5s;

		&.active {
			height: 50px;
		}
	}
}

@media screen and (max-width: 500px) {
	.step p {
		font-size: 11px;
		bottom: -20px;
	}
}

.btn.btn-primary {
	background-color: #00e277;
	border-color: #00e277;
	padding: 10px;
	width: 130px;

	&:hover {
		background-color: #00e277;
		border-color: #00e277;
	}

	&:focus,
	.active:focus,
	&:active:focus {
		box-shadow: 0 0 0 0.25rem rgb(255 87 34 / 50%);
		background-color: #00e277;
		border-color: #00e277;
	}
}

.card {
	border: none !important;
}

h3.headline {
	font-weight: 700;
	color: rgba(0, 0, 0, 0.85);
}

.form {
	margin-top: 30px;
}

.buttonContainer {
	display: flex;
	justify-content: flex-end;

	button {
		margin-left: 30px;
	}
}

.test-col {
	display: flex;
	align-items: baseline;

	.edit-icon {
		width: 15px;
		margin-left: 10px;
	}
}

input.form-control {
	height: 55px;
}
.form-control:focus {
	box-shadow: 0 0 0 0.25rem rgb(255 87 34 / 50%) !important;
}

/* CUSTOM RADIO */
.custom-radios {
	.row {
		width: 100%;
		padding: 0 20px;
		margin: 0 auto;
	}

	.form-wrapper {
		border: 1px solid #dee2e6;
		border-radius: 5px;
		height: 100px;
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		cursor: pointer;
		position: relative;

		&:hover {
			border: 2px solid #00e277;
		}
		&.active {
			border: 2px solid #00e277;
			.checked {
				background-color: #00e277;
				-webkit-mask: url(../../resources/icon-check.svg) no-repeat center;
				mask: url(../../resources/icon-check.svg) no-repeat center;
				width: 40px;
				height: 40px;
				margin: auto;
			}
		}

		.dw-version {
			position: absolute;
			font-size: 0.7em;
			top: 10px;
			right: 30px;
			span {
				display: block;
			}
		}
	}

	.version-wrapper {
		border: 1px solid #dee2e6;
		border-radius: 5px;
		height: 50px;
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		cursor: pointer;

		&:hover {
			border: 2px solid #00e277;
		}

		&.active {
			border: 2px solid #00e277;
			.checked {
				background-color: #00e277;
				-webkit-mask: url(../../resources/icon-check.svg) no-repeat center;
				mask: url(../../resources/icon-check.svg) no-repeat center;
				width: 20px;
				height: 20px;
				margin: auto;
			}
		}
	}

	input[type="radio"] {
		visibility: hidden;
	}

	.radio-text {
		margin-left: 1em;
	}

	.radio-img {
		width: 50px;
	}
}

.form-label,
.form-check-label {
	font-weight: bold;
}

.loading-overlay.active {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
	background: #fff;
	z-index: 100;

	.loading-container {
		//background: #ffffff;
		top: 50%;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		//box-shadow: 1px 1px 8px #888888;
		border-radius: 5px;
		padding: 60px;

		.loading-text {
			display: block;
		}
	}
}

/* LOADING SVG */
.loading-list {
	margin-top: 60px;
	list-style-type: none;

	.loading-icon {
		&:before {
			position: absolute;
			top: 0;
			font-size: 24px;
			font-family: FontAwesome;
			left: 50%;
			transform: translateX(-50%);
			left: 0;
		}
	}

	li {
		position: relative;

		&.loading {
			.loading-icon {
				&:before {
					content: "\f1ce";
					color: #00e277;
					animation: loading 1.2s linear infinite;
				}
			}
		}

		&.success {
			.success-icon {
				&:before {
					content: "";
					top: -6px;
					left: -24px;
					width: 64px;
					height: 44px;
					animation: tickPop 0.4s;
					background: url(https://images2.imgbox.com/de/7c/pHHdRTWX_o.png) no-repeat left
						top / 2900%;
					animation: tickPop 1s steps(28) forwards;
					position: absolute;
				}
			}
		}
	}

	span {
		margin-top: 5px;
		display: block;
		margin-left: 40px;
		font-size: 24px;
	}
}

// CHECK PROJECT NAME
.projectname-error {
	position: absolute;
	right: 0;
	margin-top: 10px;
	margin-right: 50px;

	&.loading {
		.loading-icon {
			&:before {
				position: absolute;
				top: 0;
				font-size: 24px;
				font-family: FontAwesome;
				left: 50%;
				transform: translateX(-50%);
				left: 0;
				content: "\f1ce";
				color: #00e277;
				animation: loading 1.2s linear infinite;
			}
		}
	}

	&.success {
		.success-icon {
			&:before {
				content: "";
				left: -24px;
				width: 64px;
				height: 44px;
				animation: tickPop 0.4s;
				background: url(https://images2.imgbox.com/de/7c/pHHdRTWX_o.png) no-repeat left top /
					2900%;
				animation: tickPop 1s steps(28) forwards;
				position: absolute;
			}
		}
	}

	&.error {
		.error-icon {
			position: absolute;
			top: 0;
			font-size: 24px;
			font-family: FontAwesome;
			left: 50%;
			transform: translateX(-50%);
			left: 0;

			&:before {
				content: "\f071";
				color: red;
				animation: loading 1.2s linear infinite;
			}
		}
	}
}

@keyframes tickPop {
	from {
		background-position: left top;
	}

	to {
		background-position: right top;
	}
}

@keyframes loading {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes tick {
	0% {
		-webkit-transform: scale(1.4) rotate(-35deg);
	}
	30% {
		-webkit-transform: rotate(30deg);
	}
	60% {
		-webkit-transform: scale(1) rotate(-20deg);
	}
	70% {
		-webkit-transform: rotate(10deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
	}
}

// PROGRESS LOADING
.sr-only {
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	width: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
}
.progress-bar-custom {
	height: 30px;
	width: 400px;
	--_track: hsl(228 100% 90%);
	--_track-size: min(10px, 1ex);
	--_progress: #00e277;
	--_radius: 1e3px;
	--_indeterminate-track: linear-gradient(
		to right,
		var(--_track) 45%,
		var(--_progress) 0%,
		var(--_progress) 55%,
		var(--_track) 0%
	);
	--_indeterminate-track-size: 225% 100%;
	--_indeterminate-track-animation: progress-loading 2s infinite ease;

	/*  reset  */
	appearance: none;
	border: none;

	/*  custom style  */
	position: relative;
	height: var(--_track-size);
	border-radius: var(--_radius);
	overflow: hidden;

	@media (prefers-color-scheme: dark) {
		--_track: hsl(228 20% 30%);
		--_progress: hsl(228 100% 75%);
	}

	&:focus-visible {
		outline-color: var(--_progress);
	}

	/*  Safari/Chromium  */
	&[value]::-webkit-progress-bar {
		background-color: var(--_track);
	}

	&[value]::-webkit-progress-value {
		background-color: var(--_progress);
		transition: inline-size 0.25s ease-out;
	}

	/*  Firefox  */
	&[value]::-moz-progress-bar {
		background-color: var(--_progress);
	}

	/*  indeterminate  */
	&:indeterminate::after {
		content: "";
		inset: 0;
		position: absolute;
		background: var(--_indeterminate-track);
		background-size: var(--_indeterminate-track-size);
		background-position: right;
		animation: var(--_indeterminate-track-animation);
	}

	/*  indeterminate Safari  */
	&:indeterminate::-webkit-progress-bar {
		background: var(--_indeterminate-track);
		background-size: var(--_indeterminate-track-size);
		background-position: right;
		animation: var(--_indeterminate-track-animation);
	}

	/*  indeterminate Firefox  */
	&:indeterminate::-moz-progress-bar {
		background: var(--_indeterminate-track);
		background-size: var(--_indeterminate-track-size);
		background-position: right;
		animation: var(--_indeterminate-track-animation);
	}
}
